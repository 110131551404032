import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import "./blogEditor.css";
import {
  Bold,
  Italic,
  Strikethrough,
  Code,
  Type,
  ListOrdered,
  List,
  Quote,
  Undo,
  Redo,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Image as ImageIcon,
} from "lucide-react";
import Image from "@tiptap/extension-image";
import { API } from "../../api";
import CustomImage from "./ImageTiptap";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  const Button = ({ onClick, isActive, icon: Icon, label }) => (
    <button
      onClick={onClick}
      className={`p-2 rounded-md transition-colors ${
        isActive
          ? "bg-indigo-100 text-indigo-700"
          : "text-gray-700 hover:bg-gray-100"
      }`}
      title={label}
    >
      <Icon size={18} />
    </button>
  );

  return (
    <div className="bg-white shadow-md rounded-lg p-2 mb-4">
      <div className="flex flex-wrap gap-1">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
          icon={Bold}
          label="Bold"
        />
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
          icon={Italic}
          label="Italic"
        />
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          isActive={editor.isActive("strike")}
          icon={Strikethrough}
          label="Strikethrough"
        />
        <Button
          onClick={() => editor.chain().focus().toggleCode().run()}
          isActive={editor.isActive("code")}
          icon={Code}
          label="Code"
        />
        <div className="w-px h-6 bg-gray-300 mx-1" />
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          isActive={editor.isActive("paragraph")}
          icon={Type}
          label="Paragraph"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          isActive={editor.isActive("heading", { level: 1 })}
          icon={Heading1}
          label="Heading 1"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          isActive={editor.isActive("heading", { level: 2 })}
          icon={Heading2}
          label="Heading 2"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          isActive={editor.isActive("heading", { level: 3 })}
          icon={Heading3}
          label="Heading 3"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          isActive={editor.isActive("heading", { level: 4 })}
          icon={Heading4}
          label="Heading 4"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          isActive={editor.isActive("heading", { level: 5 })}
          icon={Heading5}
          label="Heading 5"
        />
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          isActive={editor.isActive("heading", { level: 6 })}
          icon={Heading6}
          label="Heading 6"
        />
        <div className="w-px h-6 bg-gray-300 mx-1" />
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive("bulletList")}
          icon={List}
          label="Bullet List"
        />
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive("orderedList")}
          icon={ListOrdered}
          label="Ordered List"
        />
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          isActive={editor.isActive("blockquote")}
          icon={Quote}
          label="Blockquote"
        />
        <div className="w-px h-6 bg-gray-300 mx-1" />
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          icon={Undo}
          label="Undo"
        />
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          icon={Redo}
          label="Redo"
        />
        <Button
          onClick={(e) => addImage(editor, e)}
          icon={ImageIcon}
          label="ImageIcon"
        />
      </div>
    </div>
  );
};

const addImage = async (editor, e) => {
  e.preventDefault();
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.accept = "image/*";

  fileInput.onchange = async () => {
    const file = fileInput.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      //   console.log(formData , "formdata")

      try {
        // Replace with your image upload API URL
        // console.log(formData)

        const response = await API.uploadImage(formData);

        const imageUrl = response?.data?.data;

        console.log(response?.data, "imageUrl");

        // Insert the uploaded image into the editor
        editor.chain().focus().setImage({ src: imageUrl }).run();
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
  };

  fileInput.click();
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Image,
  CustomImage,
];

const BlogEditor = ({
  name,
  errors,
  label,
  register,
  setValue,
  defaultValue,
}) => {
  return (
    <div className="w-[99%] max-w-[100%] break-words border border-gray-400 rounded-[12px] p-3">
      <p className="mb-3">{label}</p>

      <EditorProvider
        slotBefore={<MenuBar />}
        extensions={extensions}
        content={defaultValue}
        onUpdate={({ editor }) => {
          const html = editor.getHTML();
          setValue(name, html);
        }}
      ></EditorProvider>

      {errors && errors[name] && (
        <p className="text-tiny text-danger pl-3 mt-1">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export default BlogEditor;
